@import "./mixin.module.scss";

$headerColor: #f2f4f8;
$pageLinkColor: #a3aebe;
$activePage: #1553b1;
$whiteColor: #fff;
$blackColor: #000;
$learnMoreColor: #397ce2;
$footerbackgroundColor: #030a14;
$borderColor: #333333;
$greyContainerColor: #2e3033;
$borderColor2: #d9d9d9;
$lineColor: #757575;
$leaderBackgroundColor: #282b32;
$inputBackgroundColor: #888;
$inputlabelColor: #707070;
$detailsContainerColor: #1d2d44;
$textAreaborder: #4c4c4c;
$redColor: #f00;
$teamIconBackgroundColor: #e0ac3c;
$languageInactiveColor: #bbc4d0;
$confirmMainTextColor: #050505;
