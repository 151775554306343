@mixin smallLaptop {
  @media only screen and (max-width: 1400px) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: 431px) {
    @content;
  }
}

@mixin samsungS8 {
  @media (min-width: 360px) and (max-width: 370px) {
    @content;
  }
}

@mixin iPhone14Pro {
  @media (min-width: 430px) and (max-width: 431px) {
    @content;
  }
}

@mixin pixel7 {
  @media (min-width: 411px) and (max-width: 412px) {
    @content;
  }
}

@mixin iPhone12Pro {
  @media (min-width: 389px) and (max-width: 391px) {
    @content;
  }
}
@mixin iPhoneSe {
  @media (min-width: 375px) and (max-width: 376px) {
    @content;
  }
}
@mixin s20Ultra {
  @media (min-width: 411px) and (max-width: 412px) {
    @content;
  }
}

@mixin laptop {
  @media only screen and (min-width: 1400px) {
    @content;
  }
}

@mixin largeDesktop {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}
