@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: "lato";
  src: local("lato"), url("./Asset/fonts/Lato-Regular.ttf") format("truetype"),
      url("https://fonts.googleapis.com/css2?family=Lato") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "lato-bold";
  src: local("lato-bold"), url("./Asset/fonts/Lato-Bold.ttf") format("truetype"),
  url("https://fonts.googleapis.com/css2?family=Lato") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "lato-bold-italic";
  src: local("lato-bold-italic"), url("./Asset/fonts/Lato-BoldItalic.ttf") format("truetype"),
  url("https://fonts.googleapis.com/css2?family=Lato") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "lato-medium";
  src: local("lato-medium"), url("./Asset/fonts/Lato-Medium.ttf") format("truetype"),
  url("https://fonts.googleapis.com/css2?family=Lato") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "lato-medium-italic";
  src: local("lato-medium-italic"), url("./Asset/fonts/Lato-MediumItalic.ttf") format("truetype"),
  url("https://fonts.googleapis.com/css2?family=Lato") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "lato-semibold";
  src: local("lato-semibold"), url("./Asset/fonts/Lato-Semibold.ttf") format("truetype"),
  url("https://fonts.googleapis.com/css2?family=Lato") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "lato-semibold-italic";
  src: local("lato-semibold-italic"), url("./Asset/fonts/Lato-SemiboldItalic.ttf") format("truetype"),
  url("https://fonts.googleapis.com/css2?family=Lato") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "lato-black";
  src: local("lato-black"), url("./Asset/fonts/Lato-Black.ttf") format("truetype"),
  url("https://fonts.googleapis.com/css2?family=Lato") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family:"Roboto";
  src: local("roboto"), url("./Asset/fonts/Roboto-Regular.ttf") format("truetype"),
  url("https://fonts.googleapis.com/css2?family=Roboto") format("truetype");
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family:"Roboto-bold";
  src: local("roboto-bold"), url("./Asset/fonts/Roboto-Bold.ttf") format("truetype"),
  url("https://fonts.googleapis.com/css2?family=Roboto") format("truetype");
  font-weight: 800;
  font-style: normal;
}


* {
  margin: 0;
  padding: 0;
}

.latoBold{
  font-family: lato-bold;
}
.latoBoldItalic{
  font-family: lato-bold-italic;
}
.latoMedium{
  font-family: lato-medium;
}
.latoMediumItalic {
  font-family: lato-medium-italic;
}
.latoSemiBold{
  font-family: lato-semibold;
}
.latoSemiBoldItalic{
  font-family: lato-semibold-italic;
}
.latoMediumItalic {
  font-family: lato-medium-italic;
}
.latoBlack {
  font-family: lato-black;
}

.robotoBold {
  font-family: Roboto-bold;
}



.pageStyle {
  font-family: "Lato", sans-serif, "Roboto" ;
}

.dflex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}