@import "../../variable.module.scss";

.headerContainer {
  background-color: $headerColor;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
  @include mobile {
    padding: 8px 20px;
  }
  .logoContainer {
  }
}
.navMobileContainer {
  margin-left: -24px;
  margin-right: -24px;
  padding-bottom: 40px;
}
.mobileIconContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .mobileIcon {
    margin-right: 8px;
  }
  .navCloseIcon {
    height: 24px;
  }
}
.pageLinks {
  width: 73%;
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: end;
  padding-left: 0;
  @include mobile {
    flex-direction: column;
    width: 100%;
    display: flex;
    // align-items: flex-start;
  }
  .link {
    color: $pageLinkColor;
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 12px;
    cursor: pointer;
    @include laptop {
      font-size: 19px;
    }
    @include smallLaptop {
      font-size: 18px;
    }
    @include mobile {
      text-align: left;
      margin-bottom: 24px;
    }
    &:hover {
      color: $activePage;
    }
  }
  .activePage {
    color: $activePage;
  }
}
.iconMarginLeft {
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.withIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include mobile {
    flex-direction: column;
  }
  // &:hover .DrawerOpen {
  //   display: block;
  // }

  // &:active {
  //   display: block;
  // }
}

.drawerShow {
  display: block;
}
.drawerHide {
  display: none;
}

.moreIcon {
  margin-left: 16px;
}
.DrawerOpen {
  width: 360px;
  border-radius: 8px;
  background-color: #f2f4f8;
  position: absolute;
  margin-top: 11%;
  z-index: 999;
  height: 139px;
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: column;
  @include smallLaptop {
    margin-top: 13%;
  }
  @include mobile {
    margin-top: 0;
  }
  .subLinks {
    color: $pageLinkColor;
    text-align: center;
    margin: 8px;
    &:hover {
      color: $activePage;
    }
  }
}

.hidePageRoutes {
  display: none !important;
}

.mobileNavDrawer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $headerColor;
  z-index: 999;
  padding: 40px 64px;
}
.marginTopHeader {
  margin-top: 12%;
}
.labelIconContainer {
  @include mobile {
    display: flex;
  }
}

.dropDownList {
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.languageDropdown {
  padding: 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  &:active {
    border: none;
  }
  .langOption {
    padding: 4px;
    background-color: $borderColor2;
    font-size: 32px;
    color: $languageInactiveColor;
    cursor: pointer;
    @include laptop {
      font-size: 19px;
    }
    .langIcon {
      height: 16px;
      width: 24px;
    }
  }
}

.languageSelectLabel {
  @include mobile {
    margin-bottom: 32px;
  }
  .languageContainer {
    width: 150px;
    height: auto;
    overflow: hidden;
  }
  
  .parentLabel {
    cursor: pointer;
    display: flex;
    align-items: center;
    z-index: 100;
    .langIconParent {
      height: 20px;
      width: 20px;
      margin-right: 8px;
      @include laptop {
        height: 18px;
        width: 18px;
      }
    }
    .label {
      text-transform: capitalize;
      font-size: 22px;
      color: $languageInactiveColor;
      @include laptop {
        font-size: 19px;
      }
    }
    .dropDownIcon {
      margin-left: 8px;
    }
  }
  .lngOptionContainer {
    position: absolute;
    list-style: none;
    padding: 12px 0;
    height: 88px;
    width: 100px;
    border-radius: 8px;
    margin-left: 16px;
    z-index: 999;
    .langOption {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 8px;
      padding: 12px;
      background-color: $whiteColor;
      font-size: 22px;
      color: $languageInactiveColor;
      cursor: pointer;
      @include laptop {
        font-size: 19px;
      }
    }
    .langActive {
      .langLiItem {
        padding: 6px 12px;
        background-color: $borderColor2;
        color: $activePage;
      }
    }
  }
}
.langIcon {
  height: 16px;
  width: 24px;
  margin-left: 8px;
}
.rmLeftMargin {
  margin-left: 0 !important;
  @include mobile {
    margin-left: -32px !important;
  }
}
