@import "../../variable.module.scss";

.loadingContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader01 {
  width: 56px;
  height: 56px;
  border: 8px solid #0052ec;
  border-right-color: transparent;
  border-radius: 50%;
  animation: loader-rotate 1s linear infinite;
}

@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
