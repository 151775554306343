@import "../../variable.module.scss";

.adminLoginContainer {
  height: 100vh;
  background-color: $headerColor;
  display: flex;
  align-items: center;
  justify-content: center;
  .adminLoginBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $borderColor;
    border-radius: 8px;
    .loginInput {
      margin: 24px 32px;
      color: $blackColor;
      width: 300px;
      border-bottom: 1px solid $borderColor;
    }
    .loginBtn {
      margin: 16px 24px;
      font-size: 24px;
      padding: 12px 24px;
      border-radius: 4px;
      border: 1px solid $borderColor;
    }
  }
}
